import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageApi } from '../../../api/imageApi.js';
import { loginApi } from '../../../api/loginApi.js';
import { NavBar } from '../../app/navBar.js';
import './homeScreen.css';

const HomeScreen = () => {
    const [imageList, setImageList] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHomepageImages = async () => {
            try {
                const loginResponse = await loginApi.loginRoles();
                if (loginResponse.status === false && loginResponse.msg === "Forbidden") {
                    navigate('/login');
                    return;
                }

                if(loginResponse.data.admin === false) {
                    navigate('/albums/' + loginResponse.data.albumID);
                    return;
                }

                const response = await imageApi.searchHomepage();
                if (response.status === false && response.msg === "Forbidden") {
                    navigate('/login');
                    return;
                }

                if (response && response.data && response.data.ids) {
                    // Initialize images without loading them
                    setImageList(response.data.ids.map(img => ({...img, src: null})));
                }
            } catch (error) {
                if (error.status === 403) {
                    navigate('/login');
                } else {
                    console.error('Error fetching the images:', error);
                }
            }
        };

        fetchHomepageImages();
    }, [navigate]);

    // Method to load an image when it comes into the viewport
    const loadImage = async (img) => {
        const blob = await imageApi.getImageBytes(img.id);
        setImageList(prevImages => prevImages.map(image =>
            image.id === img.id ? {...img, src: blob} : image
        ));
    }

    // Intersection observer logic
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const imageObj = imageList.find(function (item) { return item.id === Number(entry.target.dataset.imgId) });
                    if (imageObj) {
                        loadImage(imageObj);
                    }
                }
            });
        });

        const images = document.querySelectorAll('.grid-images img');
        images.forEach(img => {
            if (!img.src || img.src === 'path_to_placeholder.png') {
                observer.observe(img);
            }
        });

        return () => observer.disconnect(); // Cleanup on component unmount
    }, [imageList]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    }

    const handleCloseClick = () => {
        setSelectedImage(null);
    }

    const handleEditClick = (imageId) => {
        navigate(`/details/${imageId}`);
    }

    return (
        <div>
            <NavBar/>
            <div className="grid-images">
                {imageList.map(image => (
                    <img
                        key={image.id}
                        data-img-id={image.id}
                        src={image.src}
                        alt={image.title}
                        onClick={() => handleImageClick(image)}
                    />
                ))}
            </div>

            {selectedImage && (
                <div className="modal" onClick={handleCloseClick}>
                    <div className="modal-image-container">
                        <img
                            src={selectedImage.src}
                            alt={selectedImage.title}
                            className="modal-image"
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className="icon-container">
                            <span className="icon" onClick={() => handleEditClick(selectedImage.id)}>✏️</span>
                            <span className="icon icon-close" onClick={handleCloseClick}>✖️</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export { HomeScreen };