import React, { useState, useEffect, useRef } from 'react';
import { imageApi } from '../../../api/imageApi.js';
import './imageModal.css';

const RandomScreen = () => {
    const [imageSrc, setImageSrc] = useState("");
    const [clickable, setClickable] = useState(false);  // State to control clickability
    const containerRef = useRef(null);

    const fetchImage = async () => {
        try {
            const [imageUrl] = await Promise.all([
                imageApi.getRandomImageBytes(),
            ]);
            setImageSrc(imageUrl);
        } catch (error) {
            console.error("Error fetching the image:", error);
        }
    };

    useEffect(() => {
        fetchImage();
        const timer = setTimeout(() => {
            setClickable(true);  // Enable clicking after 1 second
        }, 1000);

        return () => clearTimeout(timer);  // Clean up the timer
    }, []);

    return (
        <div className="fullscreen-image-container" ref={containerRef}>
            <img
                src={imageSrc}
                alt="Random Detail"
                onClick={() => {
                    if (clickable) {  // Only allow clicking if `clickable` is true
                        fetchImage();
                    }
                }}
                style={{ cursor: clickable ? 'pointer' : 'default' }}  // Cursor changes based on clickability
            />
        </div>
    );
}

export { RandomScreen };
