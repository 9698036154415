let baseURL = "https://api.weylinrory.com/api";

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
    },
    credentials: 'include',
};

const ooFetch = (url, options) => fetch(`${baseURL}/${url}`, { ...defaultOptions, ...options });

export { ooFetch };
