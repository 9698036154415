import React, { useState } from 'react';
import { loginApi } from '../../api/loginApi.js';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async () => {
        // Assuming checkLogin function now accepts username and password
        const response = await loginApi.checkLogin(username, password);

        if (response.status) {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 30); // Add 30 days

            // Setting the cookie in the browser with 30 days expiration
            document.cookie = `user_auth=${response.data.cookie}; expires=${expiryDate.toUTCString()}; path=/; domain=.weylinrory.com;`;

            // Redirecting the user to the specified URL
            window.location.href = 'http://www.weylinrory.com/home';
        } else {
            setErrorMessage('Invalid username or password');
        }
    };

    return (
        <div>
            {errorMessage && <div>{errorMessage}</div>}
            <h2>Login</h2>
            <div>
                <label>
                    Username:
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
            </div>
            <div>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
}

export { Login };
