import React, { useState } from 'react';
import { imageApi } from '../../../api/imageApi.js';
import { NavBar } from '../../app/navBar.js';

const UploadScreen = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    const onUpload = async () => {
        if (selectedFile) {
            let response = await imageApi.uploadPhoto({ document: selectedFile });

            if (response.status === true) {
                displayBanner("Success", "green");
                setSelectedFile(null);  // Reset selected file
            } else {
                displayBanner("Failure", "red");
            }
        }
    };

    const onDrop = (e) => {
        e.preventDefault();
        let file = e.dataTransfer.files[0];
        setSelectedFile(file);
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const displayBanner = (message, type) => {
        const banner = document.createElement("div");
        banner.textContent = message;
        banner.classList.add("banner");

        if (type === "green") {  // Updated from "Success"
            banner.classList.add("success");
        } else if (type === "red") {  // Updated from "Failure"
            banner.classList.add("failure");
        }

        document.body.appendChild(banner);

        setTimeout(() => {
            banner.style.opacity = "0";
            setTimeout(() => {
                banner.remove();
            }, 500);
        }, 2000);
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <NavBar/>
            <h1>Upload</h1>
            <div
                onDrop={onDrop}
                onDragOver={onDragOver}
                style={{ border: '2px dashed #ccc', padding: '50px', width: '500px', textAlign: 'center' }}>
                {selectedFile ? selectedFile.name : 'Drag & Drop a file here or select below'}
            </div>
            <input type="file" onChange={onFileChange} />
            {selectedFile && <button onClick={onUpload}>Upload</button>}
        </div>
    );
}

export { UploadScreen };