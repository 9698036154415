import { ooFetchWithResponse } from './ooFetchWithResponse';

let tagApi = {
    getTaggablePeople: async () => {
        let url = 'tags/v1/people';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    tagPeople: async (photoID, peopleID) => {
        let url = 'tags/v1';
        let body = JSON.stringify({ photoID: photoID, peopleID: peopleID });
        let response = await ooFetchWithResponse(url, { method: "POST", body: body });
        return response;
    },
}

export { tagApi };