import { ooFetchWithResponse } from './ooFetchWithResponse';

let loginApi = {
    checkLogin: async (username, password) => {
        let url = `login/v1`;
        let body = JSON.stringify({ username, password });
        let response = await ooFetchWithResponse(url, { method: "POST", body: body });
        return response;
    },
    loginRoles: async() => {
        let url = 'login/v1';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    logout: async() => {
        document.cookie = `user_auth=null; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.weylinrory.com;`;
    }
}

export { loginApi };
