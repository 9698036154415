import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavBar } from '../../../components/app/navBar.js';
import { videoApi } from '../../../api/videoApi.js';
import './watchVideo.css';

function WatchVideo() {
    const { videoID } = useParams();
    const [videoUrl, setVideoUrl] = useState('');
    const [takenOn, setTakenOn] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [videographer, setVideographer] = useState("");
    const [isShared, setIsShared] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const urlParams = new URLSearchParams(new URL(window.location.href).search);
                const shareToken = urlParams.get('share');
                const isTokenPresent = shareToken !== null;
                setIsShared(isTokenPresent);

                if(!isTokenPresent) {
                    const [videoUrlResponse, details] = await Promise.all([
                        videoApi.getVideoURL(videoID),
                        videoApi.getVideoDetails(videoID),
                    ]);

                    setVideoUrl(videoUrlResponse.data.presignedUrl);
                    setTitle(details.data.data.title);
                    setDescription(details.data.data.description);
                    setLocation(details.data.data.location);
                    setVideographer(details.data.data.videographer);
                    setTakenOn(details.data.data.takenOn);
                } else {
                    const [videoUrlResponse] = await Promise.all([
                        videoApi.getVideoURL(videoID,shareToken),
                    ]);

                    setVideoUrl(videoUrlResponse.data.presignedUrl);
                }

            } catch (error) {
                console.error("Error fetching the image and details:", error);
            }
        })();
    }, [videoID]);

    const displayBanner = (message, type) => {
        const banner = document.createElement("div");
        banner.textContent = message;
        banner.classList.add("banner");

        if (type === "green") {  // Updated from "Success"
            banner.classList.add("success");
        } else if (type === "red") {  // Updated from "Failure"
            banner.classList.add("failure");
        }

        document.body.appendChild(banner);

        setTimeout(() => {
            banner.style.opacity = "0";
            setTimeout(() => {
                banner.remove();
            }, 500);
        }, 4000);
    };

    const handleSaveChanges = async () => {
        const params = {
            description: description,
            title: title,
            videographer: videographer,
            takenOn: takenOn,
            location: location
        };

        try {
            const response = await videoApi.editVideoDetails(videoID, params);

            if (response.status === true) {
                displayBanner("Success", "green");
            } else {
                displayBanner("Failure", "red");
            }
        } catch (error) {
            console.error("Error saving the video details:", error);
            displayBanner("Failure", "red");
        }
    };

    const handleShare = async () => {
        try {
            const response = await videoApi.shareVideo(videoID);

            if (response.status === true) {
                displayBanner("Success", "green");
                console.log(response.data.data);
                navigator.clipboard.writeText("https://www.weylinrory.com/videos/" + videoID + "?share=" + response.data.data);
            } else {
                displayBanner("Failure", "red");
            }
        } catch (error) {
            console.error("Error sharing the video:", error);
            displayBanner("Failure", "red");
        }
    };

    return (
       videoUrl ? (
        <>
        {!isShared && <NavBar />}
        <div className="video-page-container">
            <div className="video-container">
                <video controls>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            {!isShared && (
                <>
                    <div className="share-button-container">
                        <button type="button" onClick={handleShare}>
                            Share
                        </button>
                    </div>
                    <div className="form-container">
                        <form>
                    <div>
                        <label>Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Taken On:</label>
                        <input
                            type="date"
                            value={takenOn}
                            onChange={e => setTakenOn(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Location:</label>
                        <input
                            type="text"
                            value={location}
                            onChange={e => setLocation(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Videographer:</label>
                        <input
                            type="text"
                            value={videographer}
                            onChange={e => setVideographer(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Description:</label>
                        <textarea
                            rows="5"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div>
                        <button type="button" onClick={handleSaveChanges}>
                            Save Changes
                        </button>
                    </div>
                        </form>
                    </div>
                </>
            )}
        </div>
        </>
        ) : (
            <p>Loading video...</p>
        )
    );
}

export { WatchVideo };