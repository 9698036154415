import { ooFetchWithResponse } from './ooFetchWithResponse';

let albumApi = {
    getAlbums: async () => {
        let url = 'albums/v1';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getAlbumDetails: async (albumID) => {
        let url = 'albums/v1/details/' + albumID;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getPhotosInAlbum: async (albumID) => {
        let url = 'albums/v1/getPhotos/' + albumID;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getNextPhotoInAlbum: async (albumID, photoID) => {
        let url = 'albums/v1/photos/next?imageID=' + photoID + "&albumID=" + albumID;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getPreviousPhotoInAlbum: async (albumID, photoID) => {
        let url = 'albums/v1/photos/previous?imageID=' + photoID + "&albumID=" + albumID;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    sharePhotoWithMiMiPopPop: async (photoID) => {
        let url = 'share/v1/mipop';
        let body = JSON.stringify({ photoID: photoID });
        let response = await ooFetchWithResponse(url, { method: "POST", body: body });
        return response;
    },
    unsharePhotoWithMiMiPopPop: async (photoID) => {
        let url = 'share/v1/mipop';
        let body = JSON.stringify({ photoID: photoID });
        let response = await ooFetchWithResponse(url, { method: "PUT", body: body });
        return response;
    }
}

export { albumApi };