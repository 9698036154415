import { ooFetchWithResponse } from './ooFetchWithResponse';

let noteApi = {
    attachImgToNote: async(noteID, photoID) => {
        let url = 'notes/v1/' + noteID + '/photo';
        let body = JSON.stringify({photoID:photoID});
        let response = await ooFetchWithResponse(url, { method: "PUT", body: body });
        return response;
    },
    createNote: async (params) => {
        let url = 'notes/v1';
        let body = JSON.stringify(params);
        let response = await ooFetchWithResponse(url, { method: "POST", body: body });
        return response;
    },
    getNextNote: async (noteID) => {
        let url = 'notes/v1/' + noteID + '/next';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getNoteDetails: async (noteID) => {
        let url = 'notes/v1/' + noteID;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getNoteList: async () => {
        let url = 'notes/v1?who=ALL';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getPreviousNote: async (noteID) => {
        let url = 'notes/v1/' + noteID + '/previous';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
}

export { noteApi };