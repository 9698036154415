import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { imageApi } from '../../../api/imageApi.js';
import { albumApi } from '../../../api/albumApi.js';
import { loginApi } from '../../../api/loginApi.js';
import './albumScreenV2.css';

const AlbumScreenV2 = () => {
    const { albumID } = useParams();
    const [imageList, setImageList] = useState([]);
    const [albumName, setAlbumName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAlbumImages = async () => {
            try {
                const response = await albumApi.getPhotosInAlbum(albumID);
                if (response.status === false && response.msg === "Forbidden") {
                    navigate('/login');
                    return;
                }
                if (response && response.data && response.data.ids) {
                    // Initialize images without loading them
                    setImageList(response.data.ids);
                }
            } catch (error) {
                if (error.status === 403) {
                    navigate('/login');
                } else {
                    console.error('Error fetching the images:', error);
                }
            }
        };

        fetchAlbumImages();
    }, [navigate, albumID]);

    useEffect(() => {
        const fetchAlbumDetails = async () => {
            try {
                const response = await albumApi.getAlbumDetails(albumID);
                if (response.status === false && response.msg === "Forbidden") {
                    navigate('/login');
                    return;
                }
                if (response && response.data) {
                    setAlbumName(response.data.data.name)
                }
            } catch (error) {
                if (error.status === 403) {
                    navigate('/login');
                } else {
                    console.error('Error fetching the images:', error);
                }
            }
        };

        fetchAlbumDetails();
    }, [navigate, albumID]);

    // Method to load an image when it comes into the viewport
    const loadImage = async (img) => {
        const blob = await imageApi.getImageBytes(img.id);
        setImageList(prevImages => prevImages.map(image =>
            image.id === img.id ? {...img, src: blob} : image
        ));
    }

    // Intersection observer logic
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const imageObj = imageList.find(function (item) { return item.id === Number(entry.target.dataset.imgId) });
                    if (imageObj) {
                        loadImage(imageObj);
                    }
                }
            });
        });

        const images = document.querySelectorAll('.grid-images img');
        images.forEach(img => {
            if (!img.src || img.src === 'path_to_placeholder.png') {
                observer.observe(img);
            }
        });

        return () => observer.disconnect(); // Cleanup on component unmount
    }, [imageList]);

    const handleImageClick = (image) => {
        navigate(`/albums/${albumID}/${image.id}`);
    }

    const handleLogout = async () => {
        loginApi.logout();
        navigate('/login');
    };

    return (
        <div>
            <div className="header">
                <h1>Album {albumName}</h1>
                <button onClick={handleLogout}>Logout</button>
            </div>
            <div className="grid-images">
                {imageList.map(image => (
                    <img
                        key={image.id}
                        data-img-id={image.id}
                        src={image.src}
                        alt={image.title}
                        onClick={() => handleImageClick(image)}
                    />
                ))}
            </div>
        </div>
    );
}

export { AlbumScreenV2 };