import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // include stylesheets for react-quill
import { NavBar } from '../../app/navBar.js';
import { noteApi } from '../../../api/noteApi.js';

const CreateNoteScreen = () => {
    const navigate = useNavigate();
    const [recipient, setRecipient] = useState('WEYLIN');
    const [writtenOn, setWrittenOn] = useState('');
    const [title, setTitle] = useState('');
    const [noteContent, setNoteContent] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const params = {
            recipient: recipient,
            noteContent: noteContent,
            writtenOn: writtenOn,
            title: title,
        };

        try {
            const response = await noteApi.createNote(params);

            if (response.status === true) {
                displayBanner("Success", "green");
            } else {
                displayBanner("Failure", "red");
                console.error("Error saving the note:", response.msg);
            }
        } catch (error) {
            console.error("Error saving the note:", error);
            displayBanner("Failure", "red");
        }
    };

    const displayBanner = (message, type) => {
        const banner = document.createElement("div");
        banner.textContent = message;
        banner.classList.add("banner");

        if (type === "green") {  // Updated from "Success"
            banner.classList.add("success");
        } else if (type === "red") {  // Updated from "Failure"
            banner.classList.add("failure");
        }

        document.body.appendChild(banner);

        setTimeout(() => {
            banner.style.opacity = "0";
            setTimeout(() => {
                banner.remove();
            }, 500);
        }, 4000);
    };

    return (
        <div>
            <NavBar />
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="recipient">To:</label>
                        <select
                            id="recipient"
                            value={recipient}
                            onChange={e => setRecipient(e.target.value)}
                        >
                            <option value="WEYLIN">WEYLIN</option>
                            <option value="RORY">RORY</option>
                            <option value="BOTH">BOTH</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="writtenOn">Written On:</label>
                        <input
                            id="writtenOn"
                            type="date"
                            value={writtenOn}
                            onChange={e => setWrittenOn(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="title">Title:</label>
                        <input
                            id="title"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="noteContent">Note:</label>
                        <ReactQuill
                            theme="snow"
                            value={noteContent}
                            onChange={setNoteContent}
                        />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}

export { CreateNoteScreen };
