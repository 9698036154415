import { ooFetch } from './ooFetch';

let ooFetchWithResponse = async (url, options) => {
    let respObj = {};
    respObj.status = false;
    respObj.msg = '';
    respObj.data = {};

    try {
        let response = await ooFetch(url, options);

        if(response.status === 403) {
            respObj.msg = "Forbidden";
        } else if(response.status === 500) {
            let decodedResp = await response.json();
            respObj.msg = decodedResp.msg ? decodedResp.msg : 'Server Error';
        } else if(response.status === 400) {
            let decodedResp = await response.json();
            respObj.msg = decodedResp.msg ? decodedResp.msg : 'User Error';
        } else if(response.status === 200) {
            respObj.status = true;
            let decodedResp = await response.json();
            respObj.msg = decodedResp.msg;
            respObj.data = decodedResp.data;
        } else {
            console.log("Unrecognized status from API " + response.status);
        }
    } catch (e) {
        respObj.msg = "Unhandled exception " + e.toString();
    }

    return respObj;
};

export { ooFetchWithResponse };