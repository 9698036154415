import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBar } from '../app/navBar.js';

function OtherMenu() {
    const navigate = useNavigate();

    return (
        <div>
            <NavBar/>
            <ul>
              <li>
                <button onClick={() => navigate(`/albums`)} >Albums </button>
              </li>
              <li>
                <button onClick={() => navigate(`/random`)} >Random </button>
              </li>
              <li>
                <button onClick={() => navigate(`/search`)} >Search </button>
              </li>
              <li>
                <button onClick={() => navigate(`/create-note`)} >Create Note </button>
              </li>
              <li>
                <button onClick={() => navigate(`/notes`)} >View Notes </button>
              </li>
            </ul>
        </div>
    )
}

export { OtherMenu };