import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { albumApi } from '../../../api/albumApi.js';
import { imageApi } from '../../../api/imageApi.js';
import { tagApi } from '../../../api/tagApi.js';
import { NavBar } from '../../../components/app/navBar.js';
import './viewDetailsScreen.css';

const AddTagModal = ({ tags, availablePeople, setTags, setShowAddTagModal, showAddTagModal, photoID, displayBanner }) => {

    const [selectedPerson, setSelectedPerson] = useState("");

    const handleTagPerson = async () => {
        if (selectedPerson) {
            const response = await tagApi.tagPeople(photoID, selectedPerson);

            if (response.status === true) {
                displayBanner("Success", "green");
                setTimeout(() => {
                    setShowAddTagModal(false);
                }, 2000);
            } else {
                displayBanner("Failure", "red");
            }
        }
    };

    if (!showAddTagModal) return null;

    return (
        <div className="add-tag-modal-background" onClick={() => setShowAddTagModal(false)}>
            <div className="add-tag-modal-content" onClick={e => e.stopPropagation()}>
                <h4>Select a person to tag</h4>
                <select onChange={e => setSelectedPerson(e.target.value)} value={selectedPerson}>
                    <option value="">Please select</option>
                    {availablePeople.map(person => (
                        <option key={person.id} value={person.id}>{person.name}</option>
                    ))}
                </select>
                <button onClick={handleTagPerson}>Add</button>
            </div>
        </div>
    );
};

function ViewDetailsScreen() {
    const { imageID } = useParams();
    const [imageSrc, setImageSrc] = useState("");
    const [takenOn, setTakenOn] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [photographer, setPhotographer] = useState("");
    const [isTakenOnEstimate, setIsTakenOnEstimate] = useState(false);
    const [tags, setTags] = useState([]);
    const [taggablePeople, setTaggablePeople] = useState([]);
    const [showAddTagModal, setShowAddTagModal] = useState(false);
    const [error, setError] = useState(false);
    const [sharedWithMiMiPopPop, setSharedWithMiMiPopPop] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const [imageUrl, details, people] = await Promise.all([
                    imageApi.getImageBytes(imageID),
                    imageApi.getImageDetails(imageID),
                    tagApi.getTaggablePeople()
                ]);

                setImageSrc(imageUrl);
                setTitle(details.data.data.title);
                setTakenOn(details.data.data.takenOn);
                setDescription(details.data.data.description);
                setPhotographer(details.data.data.photographer);
                setLocation(details.data.data.location);
                setIsTakenOnEstimate(details.data.data.takenOnEstimate);
                setTags(details.data.data.tags);
                setTaggablePeople(people.data.data);
                setSharedWithMiMiPopPop(details.data.data.sharedMiMiPopPop);
            } catch (error) {
                console.error("Error fetching the image and details:", error);
                setError(true); // Also set error state to true in case of any exceptions
            }
        })();
    }, [imageID]);

    const displayBanner = (message, type) => {
        const banner = document.createElement("div");
        banner.textContent = message;
        banner.classList.add("banner");

        if (type === "green") {  // Updated from "Success"
            banner.classList.add("success");
        } else if (type === "red") {  // Updated from "Failure"
            banner.classList.add("failure");
        }

        document.body.appendChild(banner);

        setTimeout(() => {
            banner.style.opacity = "0";
            setTimeout(() => {
                banner.remove();
            }, 500);
        }, 4000);
    };

    const handleSaveChanges = async () => {
        const params = {
            description: description,
            title: title,
            photographer: photographer,
            takenOn: takenOn,
            location: location,
            takenOnEstimate: isTakenOnEstimate,
        };

        try {
            const response = await imageApi.editImage(imageID, params);

            if (response.status === true) {
                displayBanner("Success", "green");
            } else {
                displayBanner("Failure", "red");
            }
        } catch (error) {
            console.error("Error saving the image details:", error);
            displayBanner("Failure", "red");
        }
    };

    const resetForm = () => {
        setImageSrc("");
        setTitle("");
        setTakenOn("");
        setDescription("");
        setLocation("");
        setPhotographer("");
        setIsTakenOnEstimate(false);
        setTags([]);
    };

    const availablePeople = taggablePeople.filter(
        person => !tags.some(tag => tag.id === person.id)
    );

    const navigateToPreviousImage = () => {
        const prevImageID = Number(imageID) - 1;
        resetForm();
        navigate(`/details/${prevImageID}`);
    }

    const navigateToNextImage = () => {
        const nextImageID = Number(imageID) + 1;
        resetForm();
        navigate(`/details/${nextImageID}`);
    }

    const handleShare = async() => {
        try {
            const response = await albumApi.sharePhotoWithMiMiPopPop(imageID);

            if (response.status === true) {
                displayBanner("Success", "green");
            } else {
                displayBanner("Failure", "red");
            }
        } catch (error) {
            console.error("Error sharing the image:", error);
            displayBanner("Failure", "red");
        }
    }

    const handleUnShare = async() => {
        try {
            const response = await albumApi.unsharePhotoWithMiMiPopPop(imageID);

            if (response.status === true) {
                displayBanner("Success", "green");
            } else {
                displayBanner("Failure", "red");
            }
        } catch (error) {
            console.error("Error sharing the image:", error);
            displayBanner("Failure", "red");
        }
    }

    return (
        error ?
        <div className="error-page">
            <h2>404 Not Found</h2>
            <p>The image details you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <button onClick={() => navigate('/home')} className="home-button">Go to Home</button>
        </div>
        :
        <>
        <NavBar />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <AddTagModal
                tags={tags}
                availablePeople={availablePeople}
                setTags={setTags}
                setShowAddTagModal={setShowAddTagModal}
                showAddTagModal={showAddTagModal}
                photoID={imageID}
                displayBanner={displayBanner}
            />

            <div className="tags-container">
                {sharedWithMiMiPopPop ? (
                    <button
                        className="un-share-button"
                        onClick={handleUnShare}
                    >
                        Unshare
                    </button>
                ) : (
                    <button
                        className="share-button"
                        onClick={handleShare}
                    >
                        Share
                    </button>
                )}

                <h3>Tags:</h3>
                {tags.map(tag => (
                    <div key={tag.id} className="tag-item">
                        {tag.name} ({tag.ageInPhoto})
                        <span className="remove-tag">X</span>
                    </div>
                ))}
                <button className="add-tag" onClick={() => setShowAddTagModal(true)}>Add Tag</button>
            </div>

            <button
                onClick={navigateToPreviousImage}
                style={{ margin: '0 5px', fontSize: '24px', padding: '10px' }}>
                &lt;   {/* Left arrow */}
            </button>

            <img src={imageSrc} alt="Detail" />

            <button
                onClick={navigateToNextImage}
                style={{ margin: '0 5px', fontSize: '24px', padding: '10px' }}>
                &gt;   {/* Right arrow */}
            </button>

            <div className="form-container">
                <form>
                    <div>
                        <label>Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Taken On:</label>
                        <input
                            type="date"
                            value={takenOn}
                            onChange={e => setTakenOn(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={isTakenOnEstimate}
                                onChange={e => setIsTakenOnEstimate(e.target.checked)}
                            />
                            Is taken on an estimate?
                        </label>
                    </div>
                    <div>
                        <label>Location:</label>
                        <input
                            type="text"
                            value={location}
                            onChange={e => setLocation(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Photographer:</label>
                        <input
                            type="text"
                            value={photographer}
                            onChange={e => setPhotographer(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Description:</label>
                        <textarea
                            rows="5"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div>
                        <button type="button" onClick={handleSaveChanges}>
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
        </>
    );

}

export { ViewDetailsScreen };