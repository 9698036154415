import React from 'react';
import { loginApi } from '../../api/loginApi.js';
import { useNavigate } from 'react-router-dom';
import './navBar.css'; // Import the CSS file

function NavBar() {
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/home');
    }

    const handleUploadClick = () => {
        navigate('/upload');
    }

    const handleOtherClick = () => {
        navigate('/other');
    }

    const handleVideoClick = () => {
        navigate('/videos');
    }

    const handleLogout = async () => {
        loginApi.logout();
        navigate('/login');
    };

    return (
        <nav className="navbar">
            <button className="nav-button" onClick={handleHomeClick}>Home</button>
            <button className="nav-button" onClick={handleVideoClick}>Videos</button>
            <button className="nav-button" onClick={handleUploadClick}>Upload</button>
            <button className="nav-button" onClick={handleOtherClick}>Other</button>
            <button className="nav-button" onClick={handleLogout}>Logout</button>
        </nav>
    )
}

export { NavBar };
