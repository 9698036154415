import { ooFetchWithResponse } from './ooFetchWithResponse';

let imageApi = {
    editImage: async (imageID, params) => {
        let url = 'images/v1/' + imageID + '/details';
        let body = JSON.stringify({ params });
        let response = await ooFetchWithResponse(url, { method: "PUT", body: body });
        return response;
    },
    getImageBytes: async (imageID) => {
        let url = `https://api.weylinrory.com/api/images/v1/` + imageID;
        try {
            let response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                let error = new Error('Network response was not ok');
                error.status = response.status;  // Attach the status code to the error object
                throw error;
            }
            let blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            throw error;  // Re-throw the error to be caught by the caller
        }
    },
    getImageDetails: async (imageID) => {
        let url = 'images/v1/' + imageID + '/details';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getRandomImageBytes: async () => {
        let url = `https://api.weylinrory.com/api/random/image/v1`;
        try {
            let response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                let error = new Error('Network response was not ok');
                error.status = response.status;  // Attach the status code to the error object
                throw error;
            }
            let blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            throw error;  // Re-throw the error to be caught by the caller
        }
    },
    searchByDate: async(startDate, endDate) => {
        let url = 'search/images/v1?startDate=' + startDate + '&endDate=' + endDate;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    searchHomepage: async() => {
        let url = 'search/images/v1';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    uploadPhoto: async(data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        let url = 'images/v1';
        let response = await ooFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    }
}

export { imageApi };