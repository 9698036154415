import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { videoApi } from '../../../api/videoApi.js';
import { NavBar } from '../../app/navBar.js';
import moment from 'moment';
import './videoSelect.css';

function VideoSelect() {
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const response = await videoApi.getVideos();
                setVideos(response.data.data);
            } catch (err) {
                setError(err.message || "An error occurred.");
            }
        })();
    }, []);

    return (
        <div>
            <NavBar/>
            <h2>Please select a video</h2>

            {error ? (
                <p>{error}</p>
            ) : (
                <div className="videos-container">
                    <ul>
                        {Array.isArray(videos) && videos.map(video => (
                            <li key={video.id}>
                                <button
                                    className="albumButton"
                                    onClick={() => navigate(`/videos/${video.id}`)}
                                >
                                    📁 {video.title} : {moment(video.takenOn.date).format('MM/DD/YYYY')}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export { VideoSelect };