import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { albumApi } from '../../../api/albumApi.js';
import { NavBar } from '../../app/navBar.js';

function AlbumSelect() {
    const [albums, setAlbums] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const response = await albumApi.getAlbums();
                setAlbums(response.data.data);
            } catch (err) {
                setError(err.message || "An error occurred.");
            }
        })();
    }, []);

    return (
        <div>
            <NavBar/>
            <h2>Please select an album</h2>

            {error ? (
                <p>{error}</p>
            ) : (
                <ul>
                    {Array.isArray(albums) && albums.map(album => (
                        <li key={album.id}>
                            <button
                                className="albumButton"
                                onClick={() => navigate(`/albums/${album.id}`)}
                            >
                                📁 {album.name}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export { AlbumSelect };
