import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageApi } from '../../../api/imageApi.js';
import { NavBar } from '../../app/navBar.js';
import './searchDateScreen.css'; // You may need to create or adjust this CSS file

const SearchDateScreen = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [imageList, setImageList] = useState([]);
    const navigate = useNavigate();

    const handleSearch = async (event) => {
        event.preventDefault();
        setImageList([]);

        try {
            const response = await imageApi.searchByDate(startDate, endDate); // Ensure this API method is implemented
            if (response.status === false && response.msg === "Forbidden") {
                navigate('/login');
                return;
            }

            if (response && response.data && response.data.ids) {
                setImageList(response.data.ids.map(img => ({...img, src: null})));
            }
        } catch (error) {
            if (error.status === 403) {
                navigate('/login');
            } else {
                console.error('Error during search:', error);
            }
        }
    };

    // Method to load an image when it comes into the viewport
    const loadImage = async (img) => {
        const blob = await imageApi.getImageBytes(img.id);
        setImageList(prevImages => prevImages.map(image =>
            image.id === img.id ? {...img, src: blob} : image
        ));
    }

    // Intersection observer logic
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const imageObj = imageList.find(function (item) { return item.id === Number(entry.target.dataset.imgId) });
                    if (imageObj) {
                        loadImage(imageObj);
                    }
                }
            });
        });

        const images = document.querySelectorAll('.grid-images img');
        images.forEach(img => {
            if (!img.src || img.src === 'path_to_placeholder.png') {
                observer.observe(img);
            }
        });

        return () => observer.disconnect(); // Cleanup on component unmount
    }, [imageList]);

    const handleImageClick = (imageId) => {
        navigate(`/details/${imageId}`);
    };

    return (
        <div>
            <NavBar />
            <form onSubmit={handleSearch} className="search-form">
                <label>
                    Start Date:
                    <input 
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </label>
                <label>
                    End Date:
                    <input 
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </label>
                <button type="submit">Search</button>
            </form>

            <div className="grid-images">
                {imageList.map(image => (
                    <img
                        key={image.id}
                        data-img-id={image.id}
                        src={image.src}
                        alt={image.title}
                        onClick={() => handleImageClick(image.id)}
                    />
                ))}
            </div>
            {/* Add any additional logic for handling selectedImage as in homeScreen.js */}
        </div>
    );
}

export { SearchDateScreen };