import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NavBar } from '../../app/navBar.js';
import { noteApi } from '../../../api/noteApi.js';
import { imageApi } from '../../../api/imageApi.js';
import DOMPurify from 'dompurify';
import './noteDetails.css';

const ViewNoteDetails = () => {
    const { noteId } = useParams();
    const navigate = useNavigate();
    const [note, setNote] = useState(null);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPhotoID, setNewPhotoID] = useState('');

    const fetchNoteDetails = async (id) => {
        try {
            const response = await noteApi.getNoteDetails(id);
            if (response.status === true) {
                setNote(response.data.data);

                const photoIDs = response.data.data.photoIDs;
                if (photoIDs.length > 0) {
                    const imagePromises = photoIDs.map(async (id) => {
                        const [imageUrl, details] = await Promise.all([
                            imageApi.getImageBytes(id),
                            imageApi.getImageDetails(id),
                        ]);
                        return { id, src: imageUrl, details };
                    });
                    const imagesData = await Promise.all(imagePromises);
                    setImages(imagesData);
                }
            } else {
                console.error("Error fetching the note details:", response.msg);
            }
        } catch (error) {
            console.error("Error fetching the note details:", error);
        }
    };

    useEffect(() => {
        fetchNoteDetails(noteId);
    }, [noteId]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handlePhotoIDChange = (event) => {
        setNewPhotoID(event.target.value);
    };

    const handleAddPhotoID = async () => {
        if (!isNaN(newPhotoID) && newPhotoID.trim() !== '') {
            try {
                const response = await noteApi.attachImgToNote(noteId, newPhotoID);
                if (response.status === true) {
                    fetchNoteDetails(noteId);
                    handleCloseModal();
                } else {
                    console.error("Error adding the photo ID:", response.msg);
                }
            } catch (error) {
                console.error("Error adding the photo ID:", error);
            }
        }
    };

    const handleNextNote = async () => {
        try {
            const response = await noteApi.getNextNote(noteId);
            if (response.status === true && response.data.data.id) {
                navigate(`/view-note/${response.data.data.id}`);
            } else {
                console.error("Error fetching the next note:", response.msg);
            }
        } catch (error) {
            console.error("Error fetching the next note:", error);
        }
    };

    const handlePreviousNote = async () => {
        try {
            const response = await noteApi.getPreviousNote(noteId);
            if (response.status === true && response.data.data.id) {
                navigate(`/view-note/${response.data.data.id}`);
            } else {
                console.error("Error fetching the previous note:", response.msg);
            }
        } catch (error) {
            console.error("Error fetching the previous note:", error);
        }
    };

    if (!note) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <NavBar />
            <div className="note-details-container">
                <div className="navigation-buttons">
                    <button className="prev-note-button" onClick={handlePreviousNote}>← Next Note</button>
                    <button className="next-note-button" onClick={handleNextNote}>Previous Note →</button>
                </div>
                <h1>{note.title}</h1>
                <p><strong>To:</strong> {note.to}</p>
                <p><strong>Written On:</strong> {note.writtenOn}</p>
                <div
                    className="note-content"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note.note) }}
                />
                {images.length > 0 && (
                    <div className={`images-container ${images.length === 1 ? 'single' : 'multiple'}`}>
                        {images.map((image) => (
                            <img key={image.id} src={image.src} alt={`Detail ${image.id}`} />
                        ))}
                    </div>
                )}
                <button className="add-photo-button" onClick={handleOpenModal}>+</button>
                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Add Photo ID</h2>
                            <input
                                type="text"
                                value={newPhotoID}
                                onChange={handlePhotoIDChange}
                                placeholder="Enter photo ID"
                            />
                            <button onClick={handleAddPhotoID}>OK</button>
                            <button onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export { ViewNoteDetails };
