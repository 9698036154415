import React, { useState, useEffect, useRef, useCallback } from 'react';
import { imageApi } from '../../../api/imageApi.js';
import { albumApi } from '../../../api/albumApi.js';
import { useParams, useNavigate } from 'react-router-dom';
import './imageModal.css';

const ImageModal = ({ imageList }) => {
    const { albumID, imageID } = useParams();
    const [imageSrc, setImageSrc] = useState("");
    const [startX, setStartX] = useState(null);
    const [imageDate, setImageDate] = useState("");
    const [description, setDescription] = useState("");
    const containerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const [imageUrl, details] = await Promise.all([
                    imageApi.getImageBytes(imageID),
                    imageApi.getImageDetails(imageID),
                ]);

                setImageSrc(imageUrl);
                setImageDate(details.data.data.takenOn);
                setDescription(details.data.data.description);
            } catch (error) {
                console.error("Error fetching the image and details:", error);
            }
        })();
    }, [imageID]);

    const navigateToNextImage = useCallback(async () => {

        let response = await albumApi.getNextPhotoInAlbum(albumID,imageID);


        let photoID = response.data.nextPhotoID;

        if(photoID > 0) {
            navigate(`/albums/${albumID}/` + photoID);
        }
    }, [albumID, imageID, navigate]); // Include relevant dependencies

    const navigateToPrevImage = useCallback(async () => {
        let response = await albumApi.getPreviousPhotoInAlbum(albumID,imageID);

        let photoID = response.data.previousPhotoID;

        if(photoID > 0) {
            navigate(`/albums/${albumID}/` + photoID);
        }
    }, [albumID, imageID, navigate]); // Include relevant dependencies

    const handleTouchStart = (e) => {
        if (!e || !e.touches || e.touches.length === 0) return;
        setStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = useCallback((e) => {
        if (!e || !e.changedTouches || e.changedTouches.length === 0) return;
        if (startX === null) return;

        const endX = e.changedTouches[0].clientX;
        const threshold = 50;

        if (startX - endX > threshold) {
            navigateToNextImage();
        } else if (endX - startX > threshold) {
            navigateToPrevImage();
        }

        setStartX(null);
    }, [startX, navigateToNextImage, navigateToPrevImage]); // Include dependencies

    // New function to handle close icon click
    const closeAndNavigate = () => {
        navigate(`/albums/${albumID}`);
    };

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return; // Ensure the container exists

        container.addEventListener('touchstart', handleTouchStart);
        container.addEventListener('touchend', handleTouchEnd);

        // Clean up event listeners when component unmounts
        return () => {
            container.removeEventListener('touchstart', handleTouchStart);
            container.removeEventListener('touchend', handleTouchEnd);
        };
    }, [startX, navigateToNextImage, navigateToPrevImage, handleTouchEnd]);

    return (
        <div className="fullscreen-image-container" ref={containerRef}>
            <img src={imageSrc} alt="Detail" />
            <div className="image-date">{imageDate}</div>
            <div className="image-description">{description}</div>
            <button className="close-button" onClick={closeAndNavigate}>X</button>
            <div className="navigation">
                <span className="icon arrow-left" onClick={navigateToPrevImage}>◀️</span>
                <span className="icon arrow-right" onClick={navigateToNextImage}>▶️</span>
            </div>
        </div>
    );
}

export { ImageModal };
